<template>
  <!-- <MainTemplate /> -->
  <div />
</template>

<script>
  import MainTemplate from '../components/MainTemplate'

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    return result;
  }

  export default {
    name: 'Home',

    components: {
      MainTemplate,
    },
    mounted() {
      console.log('HomeView mounted')
      this.$router.push("/s/" + makeid(8))
    }
  }
</script>
